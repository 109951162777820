.App {
  margin: 0 auto;
}

h3 {
  margin-bottom: 0.5rem;
}

input[type="tel"] {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  min-height: 38px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 1rem
}

.card-container {
  display: flex;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 0 4px #ccc;
  width: 90%;
  max-width: 940px;
  background-color: white;
  align-items: center;
}

.input-container {
  border-left: 1px solid lightgray;
  margin-left: 16px;
  padding-left: 16px;
}

@media (max-width: 768px) {
  .card-container {
    display: block;
    padding: 16px;
    box-shadow: none;
  }

  .input-container {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 16px;
  }

  body {
    height: auto;
    overflow: auto;
  }
}

body {
  background-color: #ffffff;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #ffffff 48px), repeating-linear-gradient(#6ccdaa55, #6ccdaa);
}